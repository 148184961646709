var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"map-bar",attrs:{"app":"","dense":"","height":48}},[_c('v-toolbar-title',{staticClass:"py-1",staticStyle:{"height":"100%"}},[_c('v-btn',{attrs:{"disabled":_vm.isDrawing,"text":"","tile":"","x-large":"","exact":"","color":"primary","height":"100%"},on:{"click":_vm.backToMap}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-undo-variant ")]),_vm._v(" "+_vm._s(_vm.$t('back_to_map'))+" ")],1)],1),_c('v-subheader',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('drawer_model'))+" ")]),(_vm.editLayerNode)?_c('DrawerSave',_vm._g({scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var loading = ref.loading;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.currentDrawType || _vm.isDrawing,"loading":loading,"text":"","tile":"","x-large":"","height":"100%"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-save-outline ")]),_vm._v(" "+_vm._s(_vm.$t('save'))+" ")],1)]}}],null,false,1015645316)},_vm.$listeners)):_vm._e(),_c('DrawerNewFileDialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.currentDrawType || _vm.isDrawing,"text":"","tile":"","x-large":"","height":"100%"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-plus-outline ")]),_vm._v(" "+_vm._s(_vm.$t('save_as_new_file'))+" ")],1)]}}])}),_c('v-spacer'),_c('TranslationMenu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","tile":"","large":"","height":"100%"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-translate ")])],1)]}}])}),(_vm.userName)?_c('UserAvatar',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bg-ease",attrs:{"dark":"","text":"","tile":"","large":"","height":"100%"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle ")]),_vm._v(" "+_vm._s(_vm.userName)+" ")],1)]}}],null,false,3951017057)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }