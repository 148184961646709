var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"top":"","offset-x":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.disabled}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('select'))+" ")])]},{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"max-width":"350"}},[_c('v-card-title',{staticClass:"pa-2 grey lighten-4 font-weight-bold grey--text text--darken-1"},[_c('v-row',{staticClass:"flex-nowrap",staticStyle:{"width":"100%"},attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.step > 1)?_c('v-btn',{attrs:{"disabled":_vm.step === 1,"icon":""},on:{"click":_vm.previous}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1):_vm._e()],1),_c('v-col',{staticClass:"text-truncate menu-draggable",class:{
            'pl-2': _vm.step === 1
          },staticStyle:{"cursor":"pointer","user-select":"none"},attrs:{"title":_vm.currentTitle}},[_vm._v(" "+_vm._s(_vm.currentTitle)+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.cancel}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)],1)],1),(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":""}}):_vm._e(),_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('FileList',{attrs:{"select-file-type":_vm.selectFileType,"filter-parent-maplayer":_vm.filterParentMaplayer,"permissions":_vm.permissions},on:{"selectFileNode":_vm.selectFileNode}})],1),_c('v-window-item',{attrs:{"value":2}},[_c('FileList',{attrs:{"select-file-type":_vm.selectFileType,"filter-parent-maplayer":_vm.filterParentMaplayer,"permissions":_vm.permissions},on:{"selectFileNode":_vm.selectFileNode}})],1),_c('v-window-item',{attrs:{"value":3}},[_c('FileList',{attrs:{"select-file-type":_vm.selectFileType,"parent-maplayer-uuid":_vm.parentMaplayerUuid,"filter-parent-maplayer":_vm.filterParentMaplayer,"permissions":_vm.permissions},on:{"selectFileNode":_vm.selectFileNode}})],1)],1),_c('v-divider'),_c('v-card-actions',[(
          !_vm.selectedFileNode ||
            _vm.selectedFileNode.type !== _vm.FILE_TYPE.MAPSET
        )?_c('AddFolderDialog',{attrs:{"parent":_vm.selectedFileNode,"store-module":"globalFiles"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","nudge-top":"-20px","disabled":_vm.roleSelectedFileNode.create},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var onTooltip = ref.on;
        var attrsTooltip = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrsTooltip,false),onTooltip),[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","title":_vm.$t('page.files.ceate_folder'),"disabled":!_vm.roleSelectedFileNode.create}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-folder-plus ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('permission_denied')))])])]}}],null,false,1937722133)}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.activeFileNode},on:{"click":function($event){_vm.step < _vm.totalStep
            ?_vm.selectFileNode(_vm.activeFileNode)
            :_vm.submit(_vm.activeFileNode)}}},[_vm._v(" "+_vm._s(_vm.step >= _vm.totalStep ? _vm.$t('confirm') : _vm.$t('enter'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }